<template>
  <v-container v-if="renderNow">
    <page-title
      :page-title="
        !$language ? 'Batch Student Marks' : 'ব্যাচের শিক্ষার্থীদের মার্কস'
      "
      class="mt-5 mb-8"
    >
      <template #left>
        <v-btn
          class="mr-2"
          color="black"
          icon
          large
          @click="
            () => {
              $router.push({
                name: 'grading',
              });
            }
          "
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
    </page-title>

    <v-simple-table v-if="!isBatchEmpty">
      <thead>
        <tr>
          <th></th>
          <th v-if="batchAssignments.length > 0">
            {{ !$language ? "Assignments" : "অ্যাসাইনমেন্ট" }}
          </th>
          <th
            v-for="index in Math.max(batchAssignments.length - 1, 0)"
            :key="index"
          ></th>
          <th v-if="batchProjects.length > 0">
            {{ !$language ? "Projects" : "প্রজেক্ট" }}
          </th>
          <th
            v-for="index in Math.max(batchProjects.length - 1, 0)"
            :key="index"
          ></th>
          <th v-if="batchExams.length > 0">
            {{ !$language ? "Exams" : "পরীক্ষা" }}
          </th>
          <th
            v-for="index in Math.max(batchExams.length - 1, 0)"
            :key="index + batchAssignments.length"
          ></th>
          <th>{{ !$language ? "Percentage" : "শতাংশ" }}</th>
          <th>{{ !$language ? "Grade" : "গ্রেড" }}</th>
        </tr>
        <tr>
          <th>{{ !$language ? "Student Name" : "শিক্ষার্থীর নাম" }}</th>
          <th
            v-for="assignment in batchAssignments"
            :key="assignment.object_id"
          >
            {{ assignment.title }} ({{ assignment.total_score }})
          </th>
          <th v-for="project in batchProjects" :key="project.object_id">
            {{ project.title }} ({{ project.total_score }})
          </th>
          <th v-for="exam in batchExams" :key="exam.exam_id">
            {{ exam.title }} ({{ exam.total_points }})
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="student in batchStudents" :key="student.student_id">
          <td>{{ batchStudentNames[student.student_id] }}</td>
          <td
            v-for="assignment in batchAssignments"
            :key="assignment.object_id"
          >
            {{ getAssignmentMark(student.student_id, assignment.object_id) }}
          </td>
          <td v-for="project in batchProjects" :key="project.object_id">
            {{ getProjectMark(student.student_id, project.object_id) }}
          </td>
          <td v-for="exam in batchExams" :key="exam.id">
            {{ getExamMark(student.student_id, exam.id) }}
          </td>
          <td v-if="getStudentResult(student.student_id)">
            {{ getStudentResult(student.student_id).toFixed(2) }}
          </td>
          <td v-else>-</td>
          <td v-if="getStudentGrade(student.student_id)">
            {{ getStudentGrade(student.student_id) }}
          </td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-card
      v-else
      flat
      class="ml-6 mt-16 pt-16"
      style="
        height: 300px;
        font-size: 20px;
        color: #777777;
        text-align: center;
        justify-content: center;
      "
    >
      {{
        !$language
          ? "There is no student in this batch."
          : "এই ব্যাচে কোনো শিক্ষার্থী নেই।"
      }}
    </v-card>
  </v-container>
</template>

<script>
import gradingApi from "#ecf/grading/api";

export default {
  name: "ViewGrades",
  props: {
    courseId: {
      type: String,
      required: true,
    },
    batchId: {
      type: String,
      required: true,
    },
    partner_code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isBatchEmpty: false,
      batchMarks: undefined,
      batchAssignments: undefined,
      batchProjects: undefined,
      batchExams: undefined,
      assignmentMarks: undefined,
      projectMarks: undefined,
      examMarks: undefined,
      batchStudents: undefined,
      batchStudentNames: undefined,
      renderNow: false,
    };
  },
  computed: {},
  async created() {
    const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
    await this.getBatchResult();
    await this.getMarkDetails();
  },
  methods: {
    // Todo: use map (dict) to get student result and grade instead of running loops
    getStudentResult(studentId) {
      for (let i = 0; i < this.batchStudents.length; i++) {
        if (this.batchStudents[i].student_id === studentId)
          return "student_result" in this.batchStudents[i]
            ? this.batchStudents[i].student_result
            : undefined;
      }
    },
    getStudentGrade(studentId) {
      for (let i = 0; i < this.batchStudents.length; i++) {
        if (this.batchStudents[i].student_id === studentId)
          return "student_grade" in this.batchStudents[i]
            ? this.batchStudents[i].student_grade
            : undefined;
      }
    },
    getAssignmentMark(studentId, assignmentId) {
      if (
        studentId in this.assignmentMarks &&
        assignmentId in this.assignmentMarks[studentId]
      ) {
        return this.assignmentMarks[studentId][assignmentId];
      } else return "-";
    },
    getProjectMark(studentId, projectId) {
      if (
        studentId in this.projectMarks &&
        projectId in this.projectMarks[studentId]
      ) {
        return this.projectMarks[studentId][projectId];
      } else return "-";
    },
    getExamMark(studentId, examId) {
      if (
        studentId in this.examMarks &&
        examId in this.examMarks[studentId] &&
        this.examMarks[studentId][examId] !== null
      ) {
        return this.examMarks[studentId][examId];
      } else return "-";
    },
    async getBatchResult() {
      try {
        let response = await gradingApi.getBatchStudents({
          course_id: this.courseId,
          batch_id: this.batchId,
        });
        this.batchStudents = response.data.content["subscriptions"];
        this.batchStudentNames = response.data.content["users"];
        if (this.batchStudents.length === 0) this.isBatchEmpty = true;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message),
        ]);
      }
    },
    async getMarkDetails() {
      try {
        let response = await gradingApi.getBatchMarks({
          course_id: this.courseId,
          batch_id: this.batchId,
        });
        this.batchAssignments =
          response.data.content.batch_mark_details.batch_assignments;
        this.batchProjects =
          response.data.content.batch_mark_details.batch_projects;
        this.batchExams = response.data.content.batch_mark_details.batch_exams;
        this.assignmentMarks =
          response.data.content.batch_mark_details.assignment_marks;
        this.projectMarks =
          response.data.content.batch_mark_details.project_marks;
        this.examMarks = response.data.content.batch_mark_details.exam_marks;
        this.renderNow = true;
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message),
        ]);
      }
    },
  },
};
</script>

<style scoped></style>
