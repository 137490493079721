var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-container",
        [
          _c("page-title", {
            staticClass: "mt-5 mb-8",
            attrs: {
              "page-title": !_vm.$language
                ? "Batch Student Marks"
                : "ব্যাচের শিক্ষার্থীদের মার্কস",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "left",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "black", icon: "", large: "" },
                          on: {
                            click: () => {
                              _vm.$router.push({
                                name: "grading",
                              })
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("arrow_back")])],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1067328038
            ),
          }),
          !_vm.isBatchEmpty
            ? _c("v-simple-table", [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("th"),
                      _vm.batchAssignments.length > 0
                        ? _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "Assignments"
                                    : "অ্যাসাইনমেন্ট"
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(
                        Math.max(_vm.batchAssignments.length - 1, 0),
                        function (index) {
                          return _c("th", { key: index })
                        }
                      ),
                      _vm.batchProjects.length > 0
                        ? _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language ? "Projects" : "প্রজেক্ট"
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(
                        Math.max(_vm.batchProjects.length - 1, 0),
                        function (index) {
                          return _c("th", { key: index })
                        }
                      ),
                      _vm.batchExams.length > 0
                        ? _c("th", [
                            _vm._v(
                              " " +
                                _vm._s(!_vm.$language ? "Exams" : "পরীক্ষা") +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(
                        Math.max(_vm.batchExams.length - 1, 0),
                        function (index) {
                          return _c("th", {
                            key: index + _vm.batchAssignments.length,
                          })
                        }
                      ),
                      _c("th", [
                        _vm._v(_vm._s(!_vm.$language ? "Percentage" : "শতাংশ")),
                      ]),
                      _c("th", [
                        _vm._v(_vm._s(!_vm.$language ? "Grade" : "গ্রেড")),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    [
                      _c("th", [
                        _vm._v(
                          _vm._s(
                            !_vm.$language ? "Student Name" : "শিক্ষার্থীর নাম"
                          )
                        ),
                      ]),
                      _vm._l(_vm.batchAssignments, function (assignment) {
                        return _c("th", { key: assignment.object_id }, [
                          _vm._v(
                            " " +
                              _vm._s(assignment.title) +
                              " (" +
                              _vm._s(assignment.total_score) +
                              ") "
                          ),
                        ])
                      }),
                      _vm._l(_vm.batchProjects, function (project) {
                        return _c("th", { key: project.object_id }, [
                          _vm._v(
                            " " +
                              _vm._s(project.title) +
                              " (" +
                              _vm._s(project.total_score) +
                              ") "
                          ),
                        ])
                      }),
                      _vm._l(_vm.batchExams, function (exam) {
                        return _c("th", { key: exam.exam_id }, [
                          _vm._v(
                            " " +
                              _vm._s(exam.title) +
                              " (" +
                              _vm._s(exam.total_points) +
                              ") "
                          ),
                        ])
                      }),
                      _c("th"),
                      _c("th"),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.batchStudents, function (student) {
                    return _c(
                      "tr",
                      { key: student.student_id },
                      [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.batchStudentNames[student.student_id])
                          ),
                        ]),
                        _vm._l(_vm.batchAssignments, function (assignment) {
                          return _c("td", { key: assignment.object_id }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getAssignmentMark(
                                    student.student_id,
                                    assignment.object_id
                                  )
                                ) +
                                " "
                            ),
                          ])
                        }),
                        _vm._l(_vm.batchProjects, function (project) {
                          return _c("td", { key: project.object_id }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getProjectMark(
                                    student.student_id,
                                    project.object_id
                                  )
                                ) +
                                " "
                            ),
                          ])
                        }),
                        _vm._l(_vm.batchExams, function (exam) {
                          return _c("td", { key: exam.id }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getExamMark(student.student_id, exam.id)
                                ) +
                                " "
                            ),
                          ])
                        }),
                        _vm.getStudentResult(student.student_id)
                          ? _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .getStudentResult(student.student_id)
                                      .toFixed(2)
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("td", [_vm._v("-")]),
                        _vm.getStudentGrade(student.student_id)
                          ? _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getStudentGrade(student.student_id)
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("td", [_vm._v("-")]),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ])
            : _c(
                "v-card",
                {
                  staticClass: "ml-6 mt-16 pt-16",
                  staticStyle: {
                    height: "300px",
                    "font-size": "20px",
                    color: "#777777",
                    "text-align": "center",
                    "justify-content": "center",
                  },
                  attrs: { flat: "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.$language
                          ? "There is no student in this batch."
                          : "এই ব্যাচে কোনো শিক্ষার্থী নেই।"
                      ) +
                      " "
                  ),
                ]
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }